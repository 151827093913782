import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity';
import { createFeature, createReducer, createSelector, on } from '@ngrx/store';
import { LaunchDarklyFlagValue } from '../launch-darkly.client';
import { FeatureFlagsActions } from './feature-flags.actions';

export const featureFlagsFeatureKey = 'featureFlags';

enum FeatureFlagKeyEnum {
  'sample-feature' = 'sample-feature',
}
export type FeatureFlagKey = `${FeatureFlagKeyEnum}`;

export interface FeatureFlagView extends LaunchDarklyFlagValue {
  key: string;
  value: boolean;
}

export interface FeatureFlagsState extends EntityState<FeatureFlagView> {
  ready: boolean;
  initError: string | null;
  error: string | null;
}

export const entityAdapter: EntityAdapter<FeatureFlagView> =
  createEntityAdapter<FeatureFlagView>({
    selectId: (flag) => flag.key,
  });

export const initialFeatureFlagsState: FeatureFlagsState =
  entityAdapter.getInitialState({
    ready: false,
    initError: null,
    error: null,
  });

export const featureFlagsFeature = createFeature({
  name: featureFlagsFeatureKey,
  reducer: createReducer(
    initialFeatureFlagsState,
    on(FeatureFlagsActions.initClientSuccess, (state) => ({
      ...state,
      ready: true,
      initError: null,
    })),
    on(FeatureFlagsActions.initClientFailure, (state, { initError }) => ({
      ...state,
      initError: initError.message,
    })),
    on(FeatureFlagsActions.loadFeatureFlags, (state, { featureFlags }) =>
      entityAdapter.setAll(featureFlags, state)
    ),
    on(FeatureFlagsActions.onChangeSyncSuccess, (state, { featureFlags }) =>
      entityAdapter.upsertMany(featureFlags, state)
    ),
    on(FeatureFlagsActions.onError, (state, { error }) => ({
      ...state,
      error: error.message,
    }))
  ),
  extraSelectors: ({ selectFeatureFlagsState }) => {
    const entitySelectors = entityAdapter.getSelectors(selectFeatureFlagsState);

    return {
      ...entitySelectors,
      selectFeatureFlag: (featureFlagKey: FeatureFlagKey) =>
        createSelector(
          entitySelectors.selectEntities,
          (featureFlags) => featureFlags[featureFlagKey]?.value
        ),
    };
  },
});
