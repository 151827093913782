/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import {
  Directive,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  inject,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription, tap } from 'rxjs';
import {
  FeatureFlagKey,
  FeatureFlagsState,
  featureFlagsFeature,
} from './store/feature-flags.reducer';

@Directive({
  standalone: true,
  selector: '[squadboxFeatureFlag]',
})
export class FeatureFlagsDirective implements OnInit, OnDestroy {
  private readonly elementRef = inject(ElementRef);
  private readonly storeFeatureFlag = inject(Store<FeatureFlagsState>);

  @Input()
  squadboxFeatureFlag?: FeatureFlagKey;
  private subscription?: Subscription;

  ngOnInit(): void {
    if (!this.squadboxFeatureFlag) {
      return console.error('squadboxFeatureFlag is required');
    }

    this.subscription = this.storeFeatureFlag
      .select(featureFlagsFeature.selectFeatureFlag(this.squadboxFeatureFlag))
      .pipe(
        tap((featureFlagEnabled) => {
          featureFlagEnabled
            ? (this.elementRef.nativeElement.style.display = '')
            : (this.elementRef.nativeElement.style.display = 'none');
        })
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }
}
