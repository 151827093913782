import { createActionGroup, emptyProps, props } from '@ngrx/store';
import * as fromGenerated from '../../_generated';
import { FeatureFlagView } from './feature-flags.reducer';

export const FeatureFlagsActions = createActionGroup({
  source: 'Feature Flags Actions',
  events: {
    /**
     * Init Client
     */
    'Init Client': emptyProps(),
    'Init Client Success': emptyProps(),
    'Init Client Failure': props<{ initError: Error }>(),
    /**
     * Load Feature Flags
     */
    'Load Feature Flags': props<{ featureFlags: FeatureFlagView[] }>(),
    /**
     * Identify User
     */
    'Identify User': props<{
      user: Pick<
        fromGenerated.UserView,
        'id' | 'email' | 'firstName' | 'lastName'
      >;
    }>(),
    /**
     * On Change Sync
     */
    'On Change Sync Success': props<{ featureFlags: FeatureFlagView[] }>(),
    /**
     * On Error
     */
    'On Error': props<{ error: Error }>(),
  },
});
