import { APP_INITIALIZER, InjectionToken, Provider } from '@angular/core';
import { Store } from '@ngrx/store';
import * as LaunchDarkly from 'launchdarkly-js-client-sdk';
import { environment } from '../../environments/environment';
import { FeatureFlagsActions } from './store/feature-flags.actions';
import { FeatureFlagsState } from './store/feature-flags.reducer';

export type LaunchDarklyClient = LaunchDarkly.LDClient;
export type LaunchDarklyContext = LaunchDarkly.LDContext;
export type LaunchDarklyFlagValue = LaunchDarkly.LDFlagValue;
export type LaunchDarklyFlagSet = LaunchDarkly.LDFlagSet;

export const LAUNCH_DARKLY_CLIENT = new InjectionToken<LaunchDarklyClient>(
  'LAUNCH_DARKLY_CLIENT'
);

/**
 * How to configure LaunchDarkly SDKs to send application metadata to LaunchDarkly:
 * https://docs.launchdarkly.com/sdk/features/app-config
 */
const ldClient: LaunchDarklyClient = LaunchDarkly.initialize(
  environment.launchDarkly.envKey,
  environment.launchDarkly.context,
  environment.launchDarkly.options
);

export function provideLaunchDarklyInitializer(): Provider {
  return {
    provide: APP_INITIALIZER,
    useFactory: (featureFlagStore: Store<FeatureFlagsState>) => () =>
      featureFlagStore.dispatch(FeatureFlagsActions.initClient()),
    deps: [Store],
    multi: true,
  };
}

export function provideLaunchDarklyClient(): Provider {
  return {
    provide: LAUNCH_DARKLY_CLIENT,
    useValue: ldClient,
  };
}
